import { withRouter } from 'react-router-dom';
import React, { useState } from 'react';
import './header.scss';
import { getIsActivePath, goTo } from '../../history/history';
import { PATHS } from '../../history/paths';
import LinksComponent from '../links-menu/links-menu';
import { ReactComponent as Menu } from '../../assets/images/menu-icon.svg';

function HeaderComponent() {
  const [menuOpen, toggleMenu] = useState(false);
  function changeMenuOpen() {
    toggleMenu(!menuOpen);
  }
  function navigateTo(path: string) {
    goTo(path);
  }

  return (
    <div className='header row'>
      <div className="menu" onClick={() => changeMenuOpen()}>
        <Menu />
      </div>
      <div className="header-nav row">
        <h3 className={`${getIsActivePath(PATHS.ABOUT)}`} onClick={() => navigateTo(PATHS.ABOUT)}>About</h3>
        <div className="spacer"></div>
        <h3 className={`${getIsActivePath(PATHS.WORKS)}`} onClick={() => navigateTo(PATHS.WORKS)}>Works</h3>
      </div>

      <div className="header-logo column">
        <h1>Madhuri Pittala</h1>
        <p>Developer and Illustration Artist</p>
      </div>

      <div className="header-links">
        <a href="https://www.linkedin.com/in/madhuri-pittala-b3399a127/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
        <a href="https://www.instagram.com/art_madhuri" rel="noopener noreferrer" target="_blank">Instagram</a>
        <a href="https://github.com/pittalamadhuri" rel="noopener noreferrer" target="_blank">Github</a>
        <a href="https://dribbble.com/MadhuriPittala" rel="noopener noreferrer" target="_blank">Dribbble</a>
      </div>
      {menuOpen && <LinksComponent show={menuOpen} toggleHeaderMenu={() => changeMenuOpen()} />}
    </div>
  )
}

export default withRouter(HeaderComponent);
