import React from 'react';
import { ReactComponent as Close } from '../../assets/images/close-icon.svg';
import './links.scss';

interface Props {
  toggleHeaderMenu: () => void;
  show: boolean;
}

function LinksComponent(props: Props) {
  return (
    <div className="links-menu column">
      <div className="close" onClick={() => props.toggleHeaderMenu()}>
        <Close />
      </div>
      <div className="header-links">
        <a href="https://www.linkedin.com/in/madhuri-pittala-b3399a127/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
        <a href="https://www.instagram.com/art_madhuri" rel="noopener noreferrer" target="_blank">Instagram</a>
        <a href="https://github.com/pittalamadhuri" rel="noopener noreferrer" target="_blank">Github</a>
        <a href="https://dribbble.com/MadhuriPittala" rel="noopener noreferrer" target="_blank">Dribbble</a>
      </div>
    </div>
  )
}

export default LinksComponent;
