import React, { useState, ChangeEvent } from 'react'
import { ReactComponent as Search } from '../../assets/images/search.svg';
import './book-review.scss';
import BookCardComponent from './book-card';
import Reviews from '../../reviews/bookreview.json';
import { BookReview } from '../../helpers/types';
import { debounce } from '../../helpers/debounce';

function BookReviewComponent() {

  const [bookReviews, setBookReviews] = useState<BookReview[]>(Reviews);

  function onBookSearch(input: string): void {
    const searchedBooks = Reviews.filter((review) => {
      return review.title.toLowerCase().includes(input);
    })
    setBookReviews(searchedBooks);
  }



  function debounceSearch(e: ChangeEvent<HTMLInputElement>) {
    const searchTerm = e.target.value.toLowerCase();
    debounce<string>(onBookSearch, searchTerm, 200);
  }


  return (
    <div className="books body column">
      <div className="search">
        <input onChange={debounceSearch} type="text" placeholder={`find a book from ${Reviews.length}`} /><Search />
      </div>
      <div className="books">
        {
          bookReviews.map((review, i) => (
            <BookCardComponent key={i} {...review} />
          ))
        }
      </div>
    </div>
  )
}

export default BookReviewComponent
