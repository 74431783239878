import { createHashHistory } from "history";

export const history = createHashHistory();

export function getIsActivePath(path: string): string {
  return path === history.location.pathname? 'active' : '';
}

export function goTo(path: string) {
  history.push(path);
}