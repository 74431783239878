import React, { memo } from 'react';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { BookReview } from '../../helpers/types';


type Props = BookReview;

function BookCardComponent(props: Props) {
  return (
    <div className="book column" data-aos="fade-up">
      <div className="review">
        <div className="heading row">
          <h1>{props.title}</h1>
          <h1 className="rating">{props.rating}</h1>
        </div>
        <div className="review-brief column">
          <p>{props.description}</p>
        </div>
      </div>
      <a href={props.href} rel="noopener noreferrer" target="_blank">
        <button>Read More<Arrow /></button>
      </a>
    </div>
  )
}

export default memo(BookCardComponent);
