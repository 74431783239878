import React from 'react';
import MadhuriImageCompressed from '../../assets/images/about-madhuri-compressed.jpeg';
import MadhuriImage from '../../assets/images/madhuri-webp.webp';
import { ReactComponent as Bittu } from '../../assets/images/rabbit.svg';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import './about.scss'
import { goTo } from '../../history/history';
import { PATHS } from '../../history/paths';

function AboutComponent() {
  return (
    <div className="about body row" data-aos="fade-zoom-in">
      <div className="main-image">
        <picture>
        <source srcSet={MadhuriImageCompressed} media="(max-width: 768px)" />
        <img data-aos="fade-zoom-in" src={MadhuriImage} alt="Madhuri sitting at a lake" />
        </picture>
      </div>
      <div className="main-text column">
        <span className='greet' aria-label='Hello' role='img'>Hello 👋🏻👋🏻</span>
        <span className="story">
          I currently work as a full-stack developer at apty Inc., its exciting and challenging at the same time. I like back-end development and love front-end development.<br />
          Over the past 1 year, I have been hacking my way to a solid understanding of web development. My 2 years of experience as Customer Success executive makes me understand the importance of even smallest things while developing.
          How the user feels using a product is the most important thing which everyone creating digital products should care.
        </span>

        <h1 className="bittu">Meet Bittu
          <Bittu />
        </h1>
        <span>
          My best friend and probably the cutest pet ever. One of the best thing ever happened, when bittu came in to my life. A rabbit may be mute,but it expresses lot more than humans.
        </span>
        <h1 className="book">Read my book reviews</h1>
        <span>
          I love the smell of books. I just wish everyone has one book which they can recommend to others. For the same reason i write book reviews and would like everyone to know about them
        </span>

        <button onClick={() => goTo(PATHS.BOOKS)}>Go to book reviews <Arrow /></button>
      </div>
    </div>
  )
}

export default AboutComponent
