import AOS from 'aos';
import React, { Suspense, lazy }  from 'react';
import HeaderComponent from './components/header/header';
import AboutComponent from './components/about/about';
import BookReviewComponent from './components/book-reviews/book-review';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from './history/history';
import { PATHS } from './history/paths';
import './styles/App.scss';
import './styles/reset.scss';

const WorksComponent = lazy(() => import('./components/works/works'));

function LoadWorks() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <WorksComponent />
    </Suspense>
  )
}

function App() {
  AOS.init();
  return (
    <div className="App">
      <Router history={history}>
        <HeaderComponent />
        <Switch>
          <Route path='/' exact={true} render={() => <Redirect to={PATHS.ABOUT} />} />
          <Route path={PATHS.ABOUT} component={AboutComponent} />
          <Route path={PATHS.WORKS} component={LoadWorks} />
          <Route path={PATHS.BOOKS} component={BookReviewComponent} />
          <Route render={() => <Redirect to={PATHS.ABOUT} />} />
        </Switch>
        <div className="footer">
          <span> © 2020 Madhuri pittala</span>
        </div>
      </Router>
    </div>
  );
}

export default App;
